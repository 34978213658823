import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import image from "../images/vivi-chibi.jpg"

const IndexPage = ({ data }) => (
  <Layout>
    <SEO
      title="Home"
      keywords={[`software`, `developer`, `engineer`, `Chicago`]}
    />
    <div className="blurb">
      <p>･ﾟ: *✧･ﾟ:* I'm a software engineer living in Chicago, IL *:･ﾟ✧*:･ﾟ</p>
      <p>email: vivianxu97 [at] protonmail.com</p>
      <a href=" https://github.com/vivianxu1230/" className="bio-body">
        github: vivianxu1230
      </a>
      <img className="chibi" src={image} alt="chibi portrait" />
    </div>
    <footer>
      Bunny icon from <a href="https://icons8.com">icons8.com</a>
    </footer>
  </Layout>
)

export default IndexPage
